<template>
  <div>
    <Layout>
      <ClimateTable></ClimateTable>
    </Layout>
  </div>
</template>

<script>
import Layout from "@/views/layouts/vertical.vue";
import ClimateTable from "./climate-table.vue";

export default {
  components: { Layout, ClimateTable },
  data() {
    return {};
  },
};
</script>

<style></style>
