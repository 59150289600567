<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="right-align m-2">
        <b-button variant="primary" @click="modalShow = !modalShow">
          <i class="ri-coupon-line"></i><span class="pl-2">Add Climate</span>
        </b-button>
      </div>
    </div>
    <b-table
      striped
      hover
      :fields="fields"
      :busy="isLoading"
      :responsive="true"
      :items="items"
    >
      <template #cell(createdAt)="data">
        <span>
          {{ moment(data.item.createdAt).format("LL") }}
        </span>
      </template>
      <template #cell(updatedAt)="data">
        <span>
          {{ data.item.updatedAt && moment(data.item.updatedAt).format("LL") }}
        </span>
      </template>
      <template #cell(image)="data">
        <img
          v-if="data.item.image"
          :src="data.item.image"
          alt="Image"
          style="width: 50px; height: auto"
        />
      </template>

      <template #cell(actions)="row">
        <b-button @click="handlEdit(row.item)"> Edit </b-button>
        <b-button @click="handleDelete(row.item)"> Delete </b-button>
      </template>

      <template #table-busy>
        <div class="text-center">
          <b-spinner type="grow" variant="primary"></b-spinner>
        </div>
      </template>
    </b-table>
    <b-pagination
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    >
    </b-pagination>
    <b-modal
      id="priority-modal"
      v-model="modalShow"
      hide-footer
      @hide="closeModal"
    >
      <div class="mb-4">
        <img
          class="w-10 rounded"
          width="400"
          v-if="this.image"
          :src="this.image"
          alt="Cover image"
        />
      </div>
      <FormulateForm @submit="handleSubmit" class="mb-4" ref="couponForm">
        <FormulateInput
          v-model="name"
          validation="required"
          name="name"
          type="text"
          label="Climate Name"
        />

        <FormulateInput
          v-model="name_ar"
          validation="required"
          name="name_ar"
          type="text"
          label="Climate Name(AR)"
        />
        <FormulateInput
          type="image"
          label="Image"
          name="image"
          v-model="images"
        />

        <FormulateInput
          v-model="description"
          name="description"
          type="text"
          label="Climate Description"
        />

        <FormulateInput
          v-model="description_ar"
          name="description_ar"
          type="text"
          label="Climate Description(AR)"
        />

        <FormulateInput
          v-model="value"
          name="value"
          label="Value"
          validation="required"
        />

        <FormulateInput
          v-model="sort_value"
          name="sort_value"
          type="number"
          label="Sort Value"
          validation="required"
        />

        <b-button
          variant="primary"
          type="submit"
          class="m-auto"
          :disabled="isLoading"
        >
          <span v-if="isLoading">
            <b-spinner small class="mr-2"></b-spinner> Loading...
          </span>
          <span v-else>Submit</span>
        </b-button>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { climate } from "@/config/api/climate";
export default {
  // components: { DatePicker },
  mounted() {
    this.getReports();
  },
  watch: {
    currentPage(val) {
      this.currentPage = val;
      this.getReports();
    },
  },
  data() {
    return {
      items: [],
      dateRange: null,
      json_data: [],
      currentPage: 1,
      rows: 2,
      startDate: null,
      endDate: null,
      perPage: 5,

      fields: [
        { key: "image", label: "Image" },
        { key: "name", label: "Name" },
        { key: "name_ar", label: "Name(ar)" },
        { key: "description", label: "Description" },
        { key: "description_ar", label: "Description(ar)" },
        { key: "value", label: "value" },
        { key: "sort_value", label: "Sort value" },
        { key: "createdAt", label: "Created At" },
        { key: "updatedAt", label: "Updated At" },

        { key: "actions", label: "Actions" },
      ],
      isLoading: false,
      allReportsLoading: false,
      reportsGenerated: false,
      modalShow: false,
      name: "",
      name_ar: "",
      image: "",
      description: "",
      description_ar: "",
      value: "",
      sort_value: "",
      editModal: false,
      selectedId: null,
      images: null,
    };
  },

  methods: {
    closeModal() {
      this.name = "";
      this.name_ar = "";
      this.description = "";
      this.description_ar = "";
      this.value = "";
      this.image = null;
      this.images = null;
      this.sort_value = "";
      this.$bvModal.hide("priority-modal");
      this.$refs.couponForm.reset();
    },

    async getReports() {
      this.isLoading = true;
      this.tableData = [];
      const api = climate.getClimateForAdmin;
      api.params = { page: this.currentPage, limit: this.perPage };
      await this.generateAPI(api)
        .then((res) => {
          this.items = res.data;

          this.rows = res.data.totalCount;
          this.perPage = res.data.data.limit;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          console.log("Loaded");
        });
    },

    async handleSubmit() {
      const formData = new FormData();
      // const body = {
      //   name: this.name,
      //   name_ar: this.name_ar,
      //   value: this.value,
      //   sort_value: this.sort_value,
      // };
      formData.append("name", this.name);
      formData.append("value", this.value);
      formData.append("name_ar", this.name_ar);
      formData.append("description_ar", this.description_ar);
      formData.append("description", this.description);

      formData.append("sort_value", this.sort_value);
      if (this.images) {
        formData.append("file", this.images?.files[0]?.file);
      }
      if (this.editModal) {
        // formData.id = this.selectedId;
        formData.append("id", this.selectedId);
      }
      let api = climate.createClimateForAdmin;
      //   api.id = this.$route.params.id;/*  */
      api.data = formData;
      this.dataLoading = true;
      await this.generateAPI(api)
        .then((res) => {
          if (res) {
            this.$bvToast.toast(res?.data?.message, {
              title: "Climate",
              variant: "success", // Use 'danger' for error toasts
              solid: true,
            });
            this.getReports();
            this.modalShow = false;
            this.closeModal();
          }
        })
        .catch((err) => {
          err?.response?.data?.data.forEach((message) => {
            this.$bvToast.toast(message, {
              title: "Climate",
              variant: "danger", // Use 'danger' for error toasts
              solid: true,
            });
          });
        })
        .finally(() => {
          this.dataLoading = false;
          this.editModal = false;
          this.selectedId = null;
        });
    },
    handlEdit(row) {
      this.name = row?.name;
      this.image = row?.image;

      this.name_ar = row?.name_ar;
      this.description = row?.description;
      this.description_ar = row?.description_ar;
      this.value = row?.value;
      this.sort_value = row?.sort_value;
      this.modalShow = true;
      this.editModal = true;
      this.selectedId = row?._id;
    },
    handleDelete(item) {
      const api = climate.deleteClimateForAdmin;
      api.id = item._id;
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete the climate", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.generateAPI(api).then(() => {
              this.page = 1;
              this.getReports();
            });
          }
        });
    },
  },
};
</script>

<style></style>
