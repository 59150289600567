export const climate = {
    getClimateForAdmin: {
        url: "getClimate",
        method: "GET",
        params: null,
    },

    createClimateForAdmin: {
        url: "createUpdateClimate",
        method: "POST",
        data: null,
    },
    deleteClimateForAdmin: {
        url: "deleteClimate/",
        method: "DELETE",
        id: null,
    }

}